@import '../../../../or_base/scss/theme-bootstrap';

.block-nodeblock.block-template-gnav-email-signup-uk-v1 {
  width: 500px !important;
}

.site-email-signup {
  padding: 30px;
  &__title,
  &__subtitle,
  &__copy {
    text-align: left;
  }
  &__title {
    font-size: 16px;
    font-family: $font--futura-medium;
  }
  &__subtitle {
    font-size: 25px;
    font-style: normal;
  }
  &__field {
    width: 310px;
  }
  &__fields {
    input[type='email'],
    input[type='tel'] {
      margin-left: 0;
      height: 40px;
      @include input-placeholder {
        color: $color-dark-gray !important;
      }
    }
  }
  &__copy {
    margin-top: 8px;
  }
  &__submit {
    min-width: 120px;
    margin: 0;
    height: 30px;
    line-height: 2;
  }
  &__terms-conditions {
    &__checkbox,
    &__more {
      margin: 0 10px;
    }
  }
}
